import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const LineTextLine = makeShortcode("LineTextLine");
const SimpleReactLightbox = makeShortcode("SimpleReactLightbox");
const LightBox = makeShortcode("LightBox");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Col = makeShortcode("Col");
const ImageBed = makeShortcode("ImageBed");
const Picture = makeShortcode("Picture");
const Message = makeShortcode("Message");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="Seo" />
    <LineTextLine title={props.pageContext.frontmatter.title} mdxType="LineTextLine" />
    <SimpleReactLightbox mdxType="SimpleReactLightbox">
  <LightBox mdxType="LightBox">
    <Container mdxType="Container">
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} filename="vasilisa001.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} filename="vasilisa002.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} filename="vasilisa003.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} filename="vasilisa007.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} filename="vasilisa005.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} filename="vasilisa004.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} filename="vasilisa006.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
    </Container>
  </LightBox>
    </SimpleReactLightbox>
    <Message name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} mdxType="Message" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      